.headerdiv {
    display: flex;
    min-width: 83vw;
    justify-content: space-between;
}

.mg_t40 {
    margin-top: 40px;
}

.title_txt {
    font-size: 35px;
    margin-top: 0;
    margin-left: 60px;
    margin-bottom: 0;
    text-align: left;
    color: #00236b;
    font-weight: 700;
}

.product_form_div {
    width: 80vw;
    max-width: 100vw;
    /* margin-left: 40px; */
    margin-right: 40px;
}

.product_table_div {
    max-width: 80vw;
    margin-left: 60px;
    margin-right: 50px;
}

.product_table_div,
.product_form_div {
    max-height: calc(100vh - 160px);
    overflow-y: scroll;
    margin-top: 40px;
    scrollbar-width: 0;
}

.product_form_div::-webkit-scrollbar,
.product_table_div::-webkit-scrollbar {
    display: none;
}

.product_table {
    width: 100%;
    text-align: left;
}

.product_table tr {
    box-shadow: 0 0.5px 0.5px gray;
}

.product_table>thead>tr,
.emptyTable {
    font-size: 25px;
    text-transform: capitalize;
    color: #0a196e;
}

.product_table>tbody>tr,
.edit_action>a {
    font-size: 20px;
    text-transform: capitalize;
    color: #0a196e;
}

.product_table>tbody>tr:hover {
    box-shadow: 1px 1px 10px gray;
    border-radius: 5px;
}

.thumbnail_image {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px gray;
    margin: 10px;
}
.mg_l0{
    margin-left: 0;
}
.p_l10 {
    padding-left: 10px;
}

.align_left {
    text-align: left;
}

.align_center {
    text-align: center;
}

/* .form_tabel {
  width: 100%;
} */
.form_tabel td {
    padding-left: 50px;
}

.labeltxt {
    font-size: 20px;
    font-weight: 500;
    color: #00236b;
}

.labeltxt>label,
.labeltxt>button {
    margin-right: 50px;
}

.fileds {
    width: 400px;
    height: 50px;
    margin: 10px 0;
    padding-left: 15px;
    border-radius: 5px;
    border: 0.5px solid #042d80;
    box-shadow: 1px 1px 10px lightblue;
}

.fileds:focus-visible {
    outline: 1px solid #0a196e;
}

.btn {
    width: 200px;
    height: 40px;
    font-size: 17px;
}

.btn,
.filebtn::-webkit-file-upload-button {
    margin: 10px 0;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    background-color: #042d80;
    color: #fff;
}

.filebtn::-webkit-file-upload-button {
    width: 100px;
    height: 30px;
    margin-right: 20px;
    font-size: 15px;
    box-shadow: 1px 1px 10px lightblue;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: #042d80;
}

input:focus+.slider {
    box-shadow: 0 0 1px #042d80;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.paginationsdiv {
    max-width: 83vw;
    margin: 20px auto;
}

/* / */
.paginations {
    position: relative;
    display: flex;
    list-style: none;
    right: 0;
    margin-right: 50px;
    width: inherit;
    gap: 10px;
    padding: 0;
    justify-content: end;
}

.page_number_box {
    width: 20px;
    height: 20px;
    padding: 10px 10px;
    font-weight: 500;
    border-radius: 5px;
    text-align: center;
    color: #000046;
}

.page_number_box:hover,
.pre_next_div:hover {
    background-color: #6b99f5;
    color: #fff;
}

.pre_next_div {
    width: auto;
    height: 20px;
    padding: 10px 10px;
    font-weight: 700;
    text-align: center;
    color: #000046;
    border-radius: 5px;
}

.page_links {
    cursor: pointer;
}

.active_page {
    background-color: #000046;
    color: #fff;
}

/*  */
.title_div {
    display: flex;
    margin-left: 60px;
    margin-top: 40px;
}

.backbtn {
    margin-left: 0;
    font-size: 40px;
    cursor: pointer;
}

.edit_action {
    justify-content: center;
    gap: 10px;
    display: flex;
    /* cursor: pointer; */
}

.edit_action>a {
    cursor: pointer;
}

.alert {
    position: absolute;
    z-index: 99;
    background-color: rgb(255, 255, 255, 0.4);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
}

.alert_box {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 40vh;
    width: 40vw;
    background-color: #000046;
    border-radius: 5px;
    box-shadow: 2px 3px 10px lightblue;
    transform: translate(-50%, -50%);
    color: #fff;
}

.btn_style {
    width: 100px;
    height: 40px;
    background-color: #6b99f5;
    color: #000046;
    border: 0;
    font-size: 19px;
    border-radius: 5px;
    margin: 5px;
}
.table_searchbox_div {
    
    margin-bottom: 25px;
    float: right;
}

.table_searchbox {
    width: 300px;
    height: 40px;
    border-radius: 5px;
    margin: 5px 0;
    padding-left: 15px;
    border: 0.5px solid #042d80;
    box-shadow: 5px 5px 20px lightblue;
}
.disable{
    color:#0a196e ;
    cursor: not-allowed;
    position: relative;
    display: inline-block;
}
.error_text{
    visibility: hidden;
    width: 170px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    /* left: 50%; */
    left: -5%;
    margin-left: -60px;
}
.disable:hover{
    color: #0a196e80;
}
.disable:hover .error_text{
    visibility: visible;
}
.edit_image_div{
    position: relative;
    width: 110px;
    height: 110px;
    cursor: pointer;
   
}
.image_hover_div{
    position: absolute;
    display: none;
    width: 100px;
    height: 100px;
    top: 10px;
    right: 10px;
    background:  #00236b77;
    z-index: 99;
    border-radius: 5px;
    font-size: 30px;
    color: red;
    text-align: right;
}

.edit_image_div:hover .image_hover_div{
    display: block;
    box-shadow: 1px 1px 10px gray;
}
.date_picker > div {
width: 418px;
border-radius: 5px;
border: 0.5px solid #042d80;
box-shadow: 1px 1px 10px lightblue;
}