.auction_div_title {
    display: flex;
    justify-content: space-between;
}

.auction_title {
    margin-left: 65px;
}
.align_center {
    text-align: center;
}

.table_searchbox_div {
    margin-right: 50px;
}

.table_searchbox {
    width: 300px;
    height: 40px;
    border-radius: 5px;
    margin: 5px 0;
    padding-left: 15px;
    border: 0.5px solid #042d80;
    box-shadow: 5px 5px 20px lightblue;
}

.user_table_div {
    max-width: 80vw;
    margin-left: 60px;
    margin-right: 50px;
    max-height: calc(100vh - 160px);
    overflow-y: scroll;
    margin-top: 40px;
    scrollbar-width: 0;

}

.user_table_div::-webkit-scrollbar {
    display: none;
}

.user_table,
.info_table {
    width: 100%;
    text-align: left;
    cursor: pointer;
}

.user_table tr {
    box-shadow: 0 0.5px 0.5px grey;
}

.user_table tr:hover {
    border-radius: 5px;
    box-shadow: 1px 1px 5px grey;
}

.user_table>thead>tr,
.info_table th {
    font-size: 25px;
    text-transform: capitalize;
    color: #0a196e;
}

.user_table>tbody>tr,
.info_table td {
    font-size: 20px;
    color: #0a196e;
    height: 40px;
}

.content_right {
    justify-content: right;
}


.profile_page {
    width: 83vw;
    /* height: 80vw; */
    max-height: 100vh;
    /* overflow: scroll; */
    position: relative;
}

.profile_page::-webkit-scrollbar {
    display: none;
}

.profile_title {
    display: flex;
    gap: 50px;

    color: #042d80;
    font-weight: 700;
    margin-top: 30px;
    margin-left: 20px;
}

.profile_title>p {
    margin: 0;
    font-size: 35px;
}

.profile_title>p>svg {
    font-size: 50px;
    cursor: pointer;
}

.details_div {
    background-color: #fff;
    width: 80vw;
    height: calc(100vh - 180px);
    margin: 20px auto;
    border-radius: 5px;
    box-shadow: 5px 5px 20px lightblue;
    position: relative;
    overflow-y: scroll;
}

.details_div::-webkit-scrollbar {
    display: none;
}

.banner_div {
    margin: 20px;
    height: 250px;
}

.banner_image {
    height: inherit;
    width: 100%;
    border-radius: 15px;
}

.user_image {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    position: absolute;
    top: 5%;
    left: 3%;
    box-shadow: 1px 1px 10px black;
}

.user_name {
    font-size: 35px;
    font-weight: 700;
    color: #0a196e;
    position: absolute;
    top: 0;
    left: 15%;
}

.user_email,
.user_country,
.user_number {
    font-size: 20px;
    font-weight: 600;
    color: #0a196e;
    position: absolute;
    left: 15%;

}

.user_email {
    top: 9%;
}

.user_email>svg {
    margin-right: 5px;
    margin-top: 3px;

}

.user_number {
    top: 14%;
}

.user_country {
    top: 20%;
}

.cards {
    position: absolute;
    display: flex;
    height: 160px;
    /* background-color: #0a196e; */
    /* margin-top: 9%; */
    justify-content: center;
    gap: 50px;
    top: 5%;
    right: 3%;
}

.card {
    width: 200px;
    height: 150px;
    border-radius: 10px;
    box-shadow: 1px 1px 10px lightblue;
    transition: box-shadow ease-out 0.5s;
    cursor: pointer;
}

.card:hover {
    box-shadow: 2px 2px 20px gray;
}

.title {
    font-size: 25px;
    font-weight: 700;
    color: #0a196e;
    text-transform: capitalize;
    margin-top: 20px;
    margin-bottom: 0;
}

.info {
    font-size: 20px;
    font-weight: 500;
    color: #0a196e;
    margin-top: 20px;
    margin-bottom: 0;
}

.info>svg {
    margin-right: 5px;
}

.user_auction_div {
    /* margin: 10px 20px; */
    position: absolute;
    left: 0;
    top: 40%;
    width: 75vw;
    left: 3%;
    right: 3%;

}

.left {
    text-align: left;
    font-size: 40px;
    margin-bottom: 30px;
}
.mg_r10 {
    margin-right: 10px;
}