/* .sidemenu {
    width: 17vw;
    min-height: calc(100vh - 80px);
    border-radius: 0 5px 5px 0;
    padding: 40px 0;
    background-color: #042d80;
    color: white;
    display: flex;
    flex-direction: column;
    transform: translateX(-14vw);
    box-shadow: 2px 3px 20px rgb(68, 68, 68, 0.5);
    transition: all ease-out 0.5s;
} */

/* .sidemenu:hover {
  cursor: pointer;
   background-color: #0082c8; 
  transform: translateX(0vw);
} */

.dashboard_body {
    min-width: 83vw;
    /* height: calc(100vh - 20px); */
    height: 100vh;
    overflow-y: scroll;
    /* margin-bottom: 20px; */
}

.dashboard_body::-webkit-scrollbar {
    display: none;
}

.d_flex {
    display: flex;
}

.logoutbtn {
    background-color: transparent;
    border: 0;
    font-size: 15px;
    padding: 0;
    text-decoration: underline;
    color: beige;
    cursor: pointer;
}

.avatarimg {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    background-color: aqua;
    background-image: url(../../assets/images/maleAvatar.png);
    background-size: cover;
    margin: 10px auto;
    box-shadow: 1px 1px 5px black;
}



/* .navlistdiv {
    height: 45vh;
    margin-bottom: 70px;
    margin-left: auto;
    margin-right: auto;
}

.navlist {
    height: inherit;
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
}

.navlist>li {
    font-size: 18px;
    font-weight: 500;
    padding-left: 5px;
    transition: transform ease-out 1s;
    cursor: pointer;
}

.navlist>li:hover {
    transform: translateX(5px);
}

.navlist>li>svg {
    margin-right: 5px;
} */

.headerdiv {
    display: flex;
    min-width: 83vw;
    justify-content: space-between;
}

.mg_t40 {
    margin-top: 40px;
}

.title_txt {
    font-size: 35px;
    margin-top: 0;
    margin-left: 60px;
    margin-bottom: 0;
    text-align: left;
    color: #00236b;
    font-weight: 700;
}

.dateinfo {
    font-size: 15px;
    margin-top: 5px;
    margin-left: 60px;
    margin-bottom: 0;
    font-weight: 500;
    text-align: left;
    color: #002d88;
}

.projectbtn {
    margin-right: 50px;
    margin-top: 20px;
    width: 160px;
    height: 50px;
    font-size: 15px;
    font-weight: 700;
    border-radius: 8px;
    background-color: #0a196e;
    color: #fff;
    border: 0;
    box-shadow: 1px 1px 5px black;
    transition: box-shadow ease-out 0.6s;
    
}
.projectbtn:hover{
    box-shadow: 1px 1px 15px black;
}

.searchdiv {
    position: relative;
    margin-top: 25px;
    width: auto;
    display: flex;
    justify-content: space-around;
}

.logout_div {
    /* width: 80px;
  height: 40px; */
    width: 0;
    height: 0;
    position: absolute;

    bottom: 0px;
    right: 0px;
    border-radius: 5px;
    background-color: #0082c8;
    transition: all ease-in-out 1s;
}

.active1 {
    width: 80px;
    height: 40px;
    bottom: -45px;
}

.logout_div>button {
    width: inherit;
    height: inherit;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    border: 0;
    cursor: pointer;
    background-color: transparent;
    border-radius: 5px;
}

.searchbox {
    padding: 0 3px;
    /* position: absolute; */
    border-radius: 8px;
    /* height: 42px;
  width: 300px; */
    width: 0;
    height: 0;
    margin-right: 5px;
    font-size: 15px;
    transition: all ease-in-out 1s;
    /* border: none; */
    border-width: 0;
    box-shadow: 3px 2px 30px #d3cce3;
    /* transform: translateX(20px); */
}

.searchbox:focus-visible {
    outline: 1px solid #042d80;
}

.searchbtn {
    height: 45px;
    border-radius: 8px;
    text-align: center;
    font-size: 20px;
    width: 55px;
    font-weight: 700;
    margin-right: 15px;
    color: #0a196e;
    border: 0;
    background-color: #e9e4f0;
    padding-top: 3px;
}

.active {
    width: 300px;
    height: 42px;
    border: 0.5px solid #042d80;
}

.searchbtn:hover {
    background-color: #d3cce3;
    box-shadow: 1px 1px 3px #d3cce3;
}

.cardsdiv {
    /* min-width: calc(83vw-200px); */
    min-width: 83vw;
    display: flex;
    justify-content: space-around;
    margin-top: 60px;
    /* gap: 50px; */
    /* padding-left: 200px; */
    overflow: scroll;
}

.cards {
    width: 250px;
    height: 250px;
    box-shadow: 5px 5px 10px rgb(0, 0, 0, 0.4);
    border-radius: 8px;
    margin-left: 20px;
    margin-bottom: 10px;
    position: relative;
    color: #c6ffdd;
    transition: all 0.5s;
}

.cards:hover {
    cursor: pointer;
    box-shadow: 5px 5px 10px rgb(0, 0, 0, 0.8);
    
}

.card_color_one {
    /* color: #91eae4; */
    background-color: #1e3c72;
}

.card_color_two {
    /* color: #6dd5ed; */
    background-color: #155799;
}

.card_color_three {
    /* color: #203a43; */
    background-color: #0082c8;
}

.card_color_four {
    background-color: #62bdeb;
}

.cardsdiv::-webkit-scrollbar {
    display: none;
}

.imagestack {
    /* display: flex; */
    max-width: inherit;
    height: 50px;
    gap: 2px;
    position: relative;
}

.imagediv {
    position: absolute;
    height: 40px;
    width: 40px;
    background-color: #00236b;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 1px 1px 5px black;
}

.imageone {
    background-color: #fff;
    color: #000046;
    font-size: 20px;
    z-index: 999;
    top: 20px;
    left: 10px;
}

.imageone>svg,
.imagetwo>svg,
.imagethree>svg {
    margin-top: 10px;
}

.imagetwo {
    background-color: #fff;
    color: #000046;
    font-size: 20px;
    z-index: 99;
    top: 20px;
    left: 25px;
    transition: left ease-in-out 0.5s;
}

.imagethree {
    background-color: #fff;
    color: #000046;
    font-size: 22px;
    z-index: 1;
    top: 20px;
    left: 40px;
    transition: left ease-in-out 0.5s;
}

.imageone:hover+.imagetwo,
.imagediv:hover>.imagetwo {
    left: 55px;
}

.imageone:hover~.imagethree,
.imagediv:hover>.imagethree {
    left: 100px;
}

.imagetwo:hover {
    left: 55px;
}

.imagetwo:hover+.imagethree {
    left: 100px;
}

.imagethree:hover {
    left: 70px;
}

.imagediv>img {
    width: inherit;
    height: inherit;
}

.titlename {
    text-align: left;
    font-size: 25px;
    margin-left: 15px;
    margin-top: 30px;
    font-weight: 600;
}

.progressinfo {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.valuetxt {
    margin: 0;
    text-align: left;
    font-size: 50px;
    margin-left: 15px;
    font-weight: 600;
}

.progressdiv {
    width: 200px;
    height: 8px;
    border: 1px solid white;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
}

.progressbar {
    height: 100%;
    background-image: linear-gradient(to right, #44a08d, #4ac29a, #bdfff3);
}

.auctions_details {
    margin-top: 80px;
    margin-bottom: 50px;
}

.auctiontablediv {
    margin-top: 35px;
}

.auction_div_title {
    display: flex;
    justify-content: space-between;
}

.auction_title {
    margin-left: 65px;
}

.table_searchbox_div {
    margin-right: 50px;
}

.table_searchbox {
    width: 300px;
    height: 40px;
    border-radius: 5px;
    margin: 5px 0;
    padding-left: 15px;
    border: 0.5px solid #042d80;
    box-shadow: 5px 5px 20px lightblue;
}

.auctiontable {
    width: 90%;
    text-align: left;
    margin: 0 5%;
    border-radius: 5px;
    cursor: pointer;
}

.auctiontable tr {
    box-shadow: 0 0.5px 0.5px gray;
}

.auctiontable>thead>tr {
    height: 60px;
    font-size: 22px;
    color: #000046;
}

.auctiontable>tbody>tr {
    height: 50px;
    font-size: 17px;
    font-weight: 500;
    transition: all 0.5s;
    color: #302b63;
}

.auctiontable>tbody>tr:hover {
    box-shadow: 3px 5px 5px gray, -3px -3px 5px gray;
    border-radius: 5px;
    /* height: 60px; */
}

.paginationsdiv {
    max-width: 83vw;
    margin: 20px auto;
}

.paginations {
    position: relative;
    display: flex;
    list-style: none;
    right: 0;
    margin-right: 50px;
    width: inherit;
    gap: 10px;
    padding: 0;
    justify-content: end;
}

.page_number_box {
    width: 20px;
    height: 20px;
    padding: 10px 10px;
    font-weight: 500;
    border-radius: 5px;
    text-align: center;
    color: #000046;
}

.page_number_box:hover,
.pre_next_div:hover {
    background-color: #6b99f5;
    color: #fff;
}

.pre_next_div {
    width: auto;
    height: 20px;
    padding: 10px 10px;
    font-weight: 700;
    text-align: center;
    color: #000046;
    border-radius: 5px;
}

.page_links {
    cursor: pointer;
}

.active_page {
    background-color: #000046;
    color: #fff;
}

.auctioninfo_title_div {
    display: flex;
    gap: 10px;
    margin-left: 5%;
}

.backbtn {
    margin-left: 0;
    font-size: 40px;
    cursor: pointer;
}

.loading_disp_div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
}

.bid_log_title {
    font-size: 30px;
    margin-top: 0;
    margin-left: 50px;
    margin-bottom: 0;
    text-align: left;
    color: #00236b;
    font-weight: 700;
}

.mg_t15 {
    margin-top: 15px;
}

.current_page {
    color: #43c6ac;
}

.w80 {
    width: 80vw;
}