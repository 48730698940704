.imagediv,
.logindiv {
    height: 100vh;
    overflow: hidden;
}

.imagediv {
    width: 60%;
}

.logindiv {
    width: 40%;
}

.pointer {
    cursor: pointer;
}

.p_l62 {
    padding-left: 62px;
}

.imagesize {
    width: 100%;
    height: 900px;
}

.flexrow {
    display: flex;
    flex-direction: row;
}

.flexcol {
    display: flex;
    flex-direction: column;
}

.formdiv {
    margin: 20px 40px;
}

.mg_l {
    margin-left: 40px;
}

.txtstyle {
    font-size: 40px;
    margin-top: 25%;
    text-align: left;
    color: #00236b;
}

.btn {
    width: 100px;
    height: 40px;
    margin: 5px 0;
    font-size: 15px;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    background-color: #042d80;
    color: #fff;
    box-shadow: 5px 5px 20px lightblue;
}

.inputcheck {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
}

.inputcheck>input {
    margin: 0;
}

.reginputbox {
    width: 350px;
    height: 40px;
}

.inputbox {
    height: 50px;
    width: 400px;
}

.inputbox,
.reginputbox {
    border-radius: 5px;
    margin: 5px 0;
    padding-left: 15px;
    border: 0.5px solid #042d80;
    box-shadow: 5px 5px 20px lightblue;
}

.inputbox:focus-visible,
.reginputbox:focus-visible {
    outline: 1px solid #042d80;
}

.txt {
    text-align: left;
    margin: 20px 40px;
    font-size: 15px;
}

.switchbtn {
    background-color: transparent;
    border: 0;
    font-size: 15px;
    padding: 0;
    text-decoration: underline;
    color: blue;
    cursor: pointer;
}

.error {
    color: red;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 10px;
    width: 350px;
    text-align: justify;
}

.dashboard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.submitdiv {
    display: flex;
    gap: 10px;
    height: 80px;
}

.dot_div {
    width: 100px;
    height: 50px;
    overflow: hidden;
    display: flex;
}

.dot_one,
.dot_two,
.dot_three {
    width: 10px;
    height: 10px;
    background-color: #00236b;
    border-radius: 50%;
    position: relative;
    margin: 25px auto;
}

.dot_two {
    animation: dotFloating 1s infinite ease;
    animation-delay: 0.25s;
}

.dot_one {
    animation: dotFloatingBefore 1s infinite ease;
}

.dot_three {
    animation: dotFloatingBefore 1s infinite ease;
    animation-delay: 0.5s;
}

@keyframes dotFloating {
    0% {
        top: 0;
    }

    50% {
        top: -20px;
    }

    100% {
        top: 0;
    }
}

@keyframes dotFloatingBefore {
    0% {
        top: 0;
    }

    50% {
        top: -15px;
    }

    100% {
        top: 0;
    }
}

@keyframes dotFloatingAfter {
    0% {
        top: 0;
    }

    50% {
        top: -15px;
    }

    100% {
        top: 0;
    }
}

.passwordstrength {
    margin: 0;
}

.password_div {
    display: flex;
    gap: 10px;
}

.password_div>p {
    margin-top: 10px;
    font-size: 20px;
}

.color_orange {
    color: orangered;
}

.color_green {
    color: green;
}

.color_red {
    color: red;
}

.headerdiv {
    display: flex;
    min-width: 83vw;
    justify-content: space-between;
}

.mg_t60 {
    margin-top: 60px;
}

.mg_t40 {
    margin-top: 40px;
}

.mg_l65 {
    margin-left: 65px;
}

.mg_t20 {
    margin-top: 20px;
}

.title_txt {
    font-size: 35px;
    margin-top: 0;
    margin-left: 60px;
    margin-bottom: 0;
    text-align: left;
    color: #00236b;
    font-weight: 700;
}

.btn_style {
    margin-right: 50px;
    margin-top: 50px;
    width: 150px;
    height: 50px;
    font-size: 15px;
    font-weight: 700;
    border-radius: 8px;
    background-color: #0a196e;
    color: #fff;
    border: 0;
    box-shadow: 2px 3px 5px gray;
}

.btn_style:hover {
    box-shadow: 2px 3px 10px black;
}



.paginations {
    position: relative;
    display: flex;
    list-style: none;
    right: 0;
    margin-right: 50px;
    width: inherit;
    gap: 10px;
    padding: 0;
    justify-content: end;
}

.page_number_box {
    width: 20px;
    height: 20px;
    padding: 10px 10px;
    font-weight: 500;
    border-radius: 5px;
    text-align: center;
    color: #000046;
}

.page_number_box:hover,
.pre_next_div:hover {
    background-color: #6b99f5;
    color: #fff;
}

.pre_next_div {
    width: auto;
    height: 20px;
    padding: 10px 10px;
    font-weight: 700;
    text-align: center;
    color: #000046;
    border-radius: 5px;
}

.page_links {
    cursor: pointer;
}

.active_page {
    background-color: #000046;
    color: #fff;
}


.d_flex_center {
    display: flex;
    justify-content: center;
}


.sidemenu {
    width: 17vw;
    min-height: calc(100vh - 80px);
    border-radius: 0 5px 5px 0;
    padding: 40px 0;
    background-color: #042d80;
    color: white;
    display: flex;
    flex-direction: column;
    /* transform: translateX(-14vw); */
    box-shadow: 2px 3px 20px rgb(68, 68, 68, 0.5);
    /* transition: all ease-out 1s; */
}
/* .sidemenu:hover {
  cursor: pointer;
  transform: translateX(0vw);
} */
.navlistdiv {
    height: 45vh;
    margin-bottom: 70px;
    margin-left: auto;
    margin-right: auto;
}

.navlist {
    height: inherit;
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
}

.navlist>li {
    font-size: 18px;
    font-weight: 500;
    padding-left: 5px;
    transition: transform ease-out 1s;
    cursor: pointer;
}

.navlist>li:hover {
    transform: translateX(5px);
   
}

.navlist>li>svg {
    margin-right: 5px;
}
.avatarimg {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    background-color: aqua;
    background-image: url(../images/maleAvatar.png);
    background-size: cover;
    margin: 10px auto;
    box-shadow: 1px 1px 5px black;
}
.current_page {
    color: #43c6ac;
}
.name {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 700;
}

.email {
    margin-top: 5px;
    font-size: 15px;
    font-weight: 500;
}