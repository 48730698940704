.backnav {
    position: absolute;
    width: 100px;
    height: 250px;
    z-index: 99;
    /* background-color: antiquewhite; */
    bottom: 20px;
    left: 5px;
}

.back_btn {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: #0a196e;
    left: 50%;
    bottom: 5px;
    transform: translateX(-50%);
    border-radius: 50%;
    color: #fff;
    text-align: center;
    transition: all ease-out 0.5s;
    cursor: pointer;

}

.back_btn svg {
    margin-top: 30%;
    transition: all ease-out 0.1s;
}

.back_btn p {
    display: none;
    transition: all ease-out 0.2s;
}

.backnav:hover>.btn1 {
    bottom: 180px;
}

.backnav:hover>.btn2 {
    bottom: 120px;
}

.backnav:hover>.btn3 {
    bottom: 60px;
}

.backnav:hover>.btn4 {
    bottom: 0;
}

.back_btn:hover {
    width: 100px;
    border-radius: 5px;
    text-align: center;
}

.back_btn:hover p {
    display: block;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
}

.back_btn:hover svg {
    display: none;
}