.all_charts_div {
    /* background-color: #000046; */
    width: inherit;
    min-height: inherit;
    display: flex;
    justify-content: space-around;
    /* flex-direction: column; */
    
}

.analytics_div {
    width: 83vw;
    max-height: 100vh;
    overflow: scroll;
}

.analytics_div::-webkit-scrollbar {
    display: none;
}

.chart_div {
    width: 35vw;
    /* position: relative; */
    margin-top: 40px;
   
 

}

.chart_div,
.country_chart {
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
    /* border: 0.1px solid lightblue; */
    /* box-shadow: 2px 2px 15px lightblue; */
}

.select_div {
    width: 270px;
    height: 40px;
    padding: 5px;
    border-radius: 5px;
    font-size: 17px;
    border: 0.9px solid #000046;
    color: #00236b;
    font-weight: 500;
    box-shadow: 3px 3px 10px lightblue;
}

.select_div:focus-visible {
    outline: 2px solid #000046;
}

.d_flex {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.title {
    color: #00236b;
    text-align: center;
    margin-top: 30px;
    /* margin-left: 70px; */
    font-size: 20px;
    text-decoration: underline;
}

.country_chart_div {
    width: 77vw;
    margin: 50px auto;
}

.lable {
    margin-bottom: 10px;
    color: #00236b;
    text-align: center;
    font-size: 22px;
    text-decoration: underline;
    margin-top: 20px;
}

.mg_b40 {
    margin-bottom: 40px;
}
.chart_backgound{
    box-shadow: 1px 1px 10px lightblue;
    border-radius: 5px;
   margin-top: 50px;
}
.flex{
    display: flex;
    flex-direction: column;
}